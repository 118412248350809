@import "colors";
@import "mixins";

/*
 * Globals.
 */

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: "aktiv-grotesk", sans-serif;
    text-align: center;
}


.carapace {
    margin: 0 auto;
    padding: 0;
    width: 95%;
    max-width: 1350px;
}


a {
    text-decoration: none;
    cursor: pointer;
}


/*
 * Text classes.
 *
 * These classes are intended to specify text styles only and to
 * contain common text styles. A text element should contain both a
 * text class (to define styles common to its class) and a structure
 * class (to define styles particular to its instance).
 *
 * And they need to stay up here so they can be overridden.
 */

.section-head-main {
    font-weight: 500;
    line-height: 122%;
    letter-spacing: 1px;
}


.item-head-text-main {
    font-size: 20px;
    line-height: 165%;
    font-weight: 400;
    letter-spacing: 1px;
}


.section-head-sub,
.item-head-text-sub {
    font-size: 10px;
    line-height: 100%;
    font-weight: 300;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}


.body-text,
.body-text-wrap p {
    font-size: 15px;
    line-height: 205%;
    font-weight: 300;
}


.link-common,
.link-like {
    font-size: 21px;
    line-height: 100%;
    font-weight: 100;
    letter-spacing: 1px;
}


.arrow-link {
    display: inline-block;
    position: relative;

    &:after {
        display: inline-block;
        position: relative;
        top: 0.1em;
        margin-left: 5px;
        font-weight: 100;
        font-size: 18px;  /* 200%; */
        line-height: 0px;
        content: '\027F6';
    }
}


.black-text {
    color: $color_black;
}

.white-text {
    color: $color_white;
}


@media all and (max-width: 374px){
    .section-head-main {
        font-size: 34px;
        line-height: 122%;
    }

    .item-head-text-main {
        line-height: 150%;
    }
}

@media all and (min-width: 375px){
    .section-head-main {
        font-size: 38px;
    }
}

@media all and (min-width: 768px){
    .section-head-main {
        font-size: 49px;
    }
}

@media all and (min-width: 960px){
    .section-head-main {
        font-size: 46px;
    }
}





/*
 * Sections / layers / strata.
 */

.strata-layer,
.body-wrap,
.foot-wrap {
    position: relative;
    display: block;
    width: 100%;
}


.strata-layer {
    margin: 0;
    padding: 0;
}


.layer-wrap {
    margin: 0 auto;
}


.black-on-white {
    color: $color_black;
    background-color: $color_white;

    a {
        color: $color_black;
    }
}


.white-on-black {
    color: $color_white;
    background-color: $color_black;

    a {
        color: $color_white;
    }
}

@media all and (max-width: 374px){
    .layer-wrap {
        padding: 25px 0 5px 0;
    }
}





/*
 * Nav.
 */

.nav-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.nav-link-logo {
    position: absolute;
    top: 25px;
    right: 24px;
    margin: 0;
    padding: 10px;
    border: 0;
}

.nav-logo-img {
    display: inline-block;
}

.nav-list-wrap {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 33px;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
}

.nav-list-item,
.nav-list-link {
    display: block;
}

.nav-list-item {
    margin: 5px 0;
    padding: 0;

    &:first-child {
        margin-top: 10px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.nav-list-link {
    margin: 14px 0;
    padding: 0;
    font-size: 16px;
}

.nav-mobile-list-link {
    color: $color_white;
    font-size: 37px;
    line-height: 200%;
}

.nav-list-line,
.nav-list-line:before,
.nav-list-line:after {
    position: fixed;
    display: block;
    content: "";
}

.nav-list-line-black,
.nav-list-line-black:before,
.nav-list-line-black:after {
    background: $color_black;
}

.nav-list-line-white,
.nav-list-line-white:before,
.nav-list-line-white:after {
    background: $color_white;
}

.nav-list-hamburger{
    position: fixed;
    z-index: 100;
    cursor: pointer;
}

.nav-list-modal{
    display: none;
    background-color: $color_black;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 100;
}

.nav-mobile-nav {
    list-style: none;
    text-align: left;
}

.nav-mobile-list-link {
    user-select: none;
}

.nav-mobile-list-link-active {
    color: $color_grey;
}

.modal-close:hover{
    cursor: pointer;
}



@media all and (max-width: 374px){

    .contact-page-map-flap {
        height: 130px;
    }

    .nav-logo-img {
        width: 70px;
    }

    .nav-list-link {
        display: none;
    }

    .nav-list-hamburger {
        height: 60px;
        width: 60px;
    }

    .nav-list-line {
        margin: 46px 0 0 31px;
    }

    .nav-list-line,
    .nav-list-line:before,
    .nav-list-line:after {
        width: 40px;
        height: 3px;
    }

    .nav-list-line:before {
        top: 35px;
    }

    .nav-list-line:after {
        top: 57px;
    }

    .modal-close,
    .modal-close:before {
        display: block;
        width: 32px;
        height: 3px;
        position: relative;
        content: "";
        background: $color_white;
        opacity: 1;
    }

    .modal-close {
        transform: rotate(43deg);
        margin: 40px 0 0 25px;
    }

    .modal-close:before {
        transform: rotate(92deg);
    }

    .nav-mobile-nav {
        margin-top: 13%;
        padding-left: 25px;
    }
}

@media all and (min-width: 375px){
    .contact-page-map-flap {
        height: 120px;
    }

    .nav-logo-img {
        width: 70px;
    }

    .nav-list-link {
        display: none;
    }

    .nav-list-hamburger {
        height: 60px;
        width: 60px;
    }

    .nav-list-line {
        margin: 46px 0 0 31px;
    }

    .nav-list-line,
    .nav-list-line:before,
    .nav-list-line:after {
        width: 40px;
        height: 3px;
    }

    .nav-list-line:before {
        top: 35px;
    }

    .nav-list-line:after {
        top: 57px;
    }

    .modal-close,
    .modal-close:before {
        display: block;
        width: 32px;
        height: 3px;
        position: relative;
        content: "";
        background: $color_white;
        opacity: 1;
    }

    .modal-close {
        transform: rotate(43deg);
        margin: 40px 0 0 30px;
    }

    .modal-close:before {
        transform: rotate(92deg);
    }

    .nav-mobile-nav {
        margin-top: 25%;
        padding-left: 30px;
    }

}

@media all and (min-width: 768px){
    .contact-page-map-flap {
        height: 150px;
    }

    .nav-logo-img {
        width: 88px;
    }

    .nav-list-link {
        display: none;
    }

    .nav-list-hamburger {
        height: 60px;
        width: 60px;
    }

    .nav-list-line {
        margin: 50px 0 0 31px;
    }

    .nav-list-line,
    .nav-list-line:before,
    .nav-list-line:after {
        width: 50px;
        height: 4px;
    }

    .nav-list-line:before {
        top: 35px;
    }

    .nav-list-line:after {
        top: 65px;
    }

    .modal-close,
    .modal-close:before {
        display: block;
        width: 50px;
        height: 4px;
        position: relative;
        content: "";
        background: $color_white;
        opacity: 1;
    }

    .modal-close {
        transform: rotate(43deg);
        margin: 45px 0 0 30px;
    }

    .modal-close:before {
        transform: rotate(92deg);
    }

    .nav-mobile-nav {
        margin-top: 30%;
        padding-left: 90px;
    }
}

@media all and (min-width: 960px){
    .contact-page-map-flap {
        height: 130px;
    }

    .nav-logo-img {
        width: 73px;
    }

    .nav-list-link {
        font-size: 16px;
    }

    .nav-mobile-nav {
        margin: 20% 0 0 40px;
    }
}

@media all and (min-width: 1024px){
    .nav-list-hamburger{
        display: none;
    }

    .nav-list-link {
        display: inline;
    }
}



/*
 * Headers.
 */

.header-text-wrap {
    position: relative;
    margin: 0 auto;
    width: 72%;
    padding: 230px 0 25px 0;
    text-align: left;
}

.header-text-wrap-first {
    padding: 136px 0 25px 0;
}


.header-text-main {
    position: relative;
    margin: 26px 0 0 0;
    padding: 0;

    /* This is for tags inserted by the Rich Text field. */
    & * {
        margin: 0;
        padding: 0;
    }
}


.header-text-sub {
    position: relative;
    margin-bottom: 15px;
    padding: 0;
}

@media all and (max-width: 374px){
    .header-text-wrap {
        padding: 120px 0 25px 0;
        width: 90%;
        margin: 0 0 0 22px;
    }
}

@media all and (min-width: 375px){
    .header-text-wrap {
        padding: 108px 0 25px 0;
        width: 80%;
        margin: 0 0 0 20px;
    }

}

@media all and (min-width: 768px){
    .header-text-wrap {
        margin: 0 0 0 110px;
        width: 70%;
    }
}

@media all and (min-width: 960px){
    .header-text-wrap-multi {
        padding-top: 338px;
        margin: 0 0 0 105px;
    }


    .header-text-wrap-first {
        margin: 0 0 0 100px;
    }
}

@media all and (min-width: 1024px) {
    .header-text-wrap-multi {
        padding-top: 338px;
        margin: 0 0 0 105px;
    }

}





/*
 * Body text.
 */

.body-text-wrap {
    position: relative;
    padding: 0;
    text-align: left;

    p {
        max-width: 650px;
        margin: 30px 0;
        padding: 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .profile-page-wrap & {
            max-width: none;
        }
    }
}

@media all and (max-width: 374px) {
    .body-text-wrap {
        width: 85%;
        margin: 0 0 5% 22px;

        .profile-page-wrap & {
            margin-bottom: 0;
        }
    }
}

@media all and (min-width: 375px){
    .body-text-wrap {
        width: 85%;
        margin: 0 0 5% 20px;

        .profile-page-wrap & {
            width: calc(95% - 20px);
            margin-bottom: 0 !important;
        }
    }

    .single-image-wrap {
        margin: 85px auto;
    }
}

@media all and (min-width: 768px){
    .body-text-wrap {
        width: 68%;
        margin: 0 0 5% 110px;

        .profile-page-wrap & {
            width: calc(95% - 110px);
        }
    }
}

@media all and (min-width: 960px) {
    .body-text-wrap {
        width: 43%;
        margin: 0 0 5% 100px;

        .profile-page-wrap & {
            width: calc(95% - 100px);
        }
    }
}

@media all and (min-width: 1024px) {
    .body-text-wrap {
        width: 43%;
        margin: 0 0 5% 105px;

        .profile-page-wrap & {
            width: calc(95% - 105px);
        }
    }
}




/* .body-text-wrap + .single-image-wrap {
 */
/*     margin-top: 40px; */
/* } */


/* .single-image-wrap + .body-text-wrap {
 */
/*     margin-top: 40px; */
/* } */





/*
 * Fullscreen video blocks.
 */

.fullscreen-video-wrap,
.fullscreen-img-bg {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /* The background-image is set in the template. */
}


.fullscreen-video-img {
    @include abscent;
    min-width: 100%;
    min-height: 100%;
}

.fullscreen-img-bg--landscape {
    display: none;
}

.fullscreen-img-bg--portrait {
    display: block;
}


.fullscreen-video-text-wrap {
    position: absolute;
    bottom: 27%;
    left: 60.5%;
    transform: translateX(-61%);
    width: 74%;
    text-align: left;
}


.fullscreen-video-text-cell {
    display: inline-block;
    vertical-align: top;
    width: 49%;

    &:first-child {
        text-align: left;
    }

    &:last-child {
        text-align: right;
    }
}


.fullscreen-video-text-head {
    position: relative;
    margin: 0;
    padding: 0;
}


.fullscreen-video-text-subhead {
    position: relative;
    margin: 15px 0 0 0;
    padding: 0;
}


.fullscreen-video-text-link {
    font-size: 15px;
}


.fullscreen-video-swap-vid {
    display: none;
}


@media all and (max-width: 374px) {
    .fullscreen-video-text-wrap {
        left: 57%;
    }
}


@media all and (min-width: 375px) {
    .fullscreen-video-text-wrap {
        left: 53%;
    }
}


@media all and (min-width: 768px) {
    .fullscreen-video-text-wrap {
        left: 56%;
    }
}


@media all and (min-width: 960px) {
    .fullscreen-video-text-wrap {
        left: 58%;
    }

    .fullscreen-video-swap-vid {
        display: block;
    }

    .fullscreen-img-bg--landscape {
        display: block;
    }

    .fullscreen-img-bg--portrait {
        display: none;
    }
}


@media all and (max-width: 600px) {
    .fullscreen-video-text-cell {
        display: block;
        margin: 10px 0 0 0;
        width: 100%;
        text-align: left;

        &:last-child {
            text-align: left;
        }
    }

    .fullscreen-video-text-link {
        margin-top: 0;
    }
}



/*
 * Single images.
 */

.single-image-wrap {
    position: relative;
    margin: 120px auto;
}

.single-image-img {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

@media all and (max-width: 374px) {
    .single-image-wrap {
        margin: 42px auto;
    }
}



/*
 * Image pair.
 */

.image-pair-wrap {
    position: relative;
    margin: 120px auto;
    padding: 0;
    width: 100%;
}



/*
 * Image trains.
 */

.image-train-wrap {
    position: relative;
    width: 100%;
    padding: 0;
    overflow: hidden;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;

    &[draggable] {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;
    }
}

.image-train-body-wrap {
    position: relative;
    height: 100%;
    font-size: 0;  /* This eliminates whitespace caused by linebreaks, etc., in the HTML. */
    white-space: nowrap;
    transition: transform 0.6s ease;

    .image-train-wrap[draggable] > & {
        transition: none;
    }
}

.image-train-cell {
    display: inline-block;
    height: 100%;
    padding: 0 15px;
    margin: 0;
    vertical-align: middle;
}

.image-train-img {
    display: block;
    max-height: 60vh;
    margin: 0;
    padding: 0;
}

@media all and (max-width: 374px) {
    .image-train-wrap {
        margin: 40px 0 0 0;
    }

    .image-train-img {
        max-width: 70vw;
        margin-bottom: 35px;
    }
}

@media all and (min-width: 375px) {
    .image-train-img {
        max-width: 72vw;
    }
}

@media all and (min-width: 960px) {
    .image-train-wrap {
        padding-bottom: 50px;
    }
    .image-train-img {
        max-width: 80vw;
        max-height: 50vh;
    }
}

@media all and (min-width: 1024px) {
    .image-train-wrap {
        padding-bottom: 20px;
    }

    .image-train-img {
        max-height: 60vh;
    }
}



/*
 * Image grid/train swap rules.
 * The image grid/train swap rules are set in craft/templates/content-blocks/image-grid.html
 */



/*
 * Image grids.
 */

.image-grid-wrap {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
}

.image-grid {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 0;
}

.image-grid-sizer {
    width: 20%;
}

.image-grid-item {
    margin: 0;
    padding: 0;
    background-position: center center;
    background-size: cover;
}



/*
 * Lists for awards, bullet points, staff, etc.
 */

.awards-table {
    margin-left: 12px;
    text-align: left;
}

.awards-table-item-head-main {
    margin: 0;
}

.awards-table-item td {
    vertical-align: top;
}

.awards-table-item td:first-child {
    padding-right: 18px;
}

.awards-list,
.misc-list,
.news-list,
.points-list,
.staff-list {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
}

.misc-list-wrap {
    /* This is to align the list with the other list types. */
    /* The others pad their items on both sides. */
    padding: 0 30px;
}

.awards-list-item,
.news-list-item,
.points-list-item,
.staff-list__item {
    display: block;
    vertical-align: top;
}

.misc-list-item {
    display: block;
    margin: 0 0 2px 0; /* This is to align the top items in the columns. */
    padding: 0;
    vertical-align: top;
}

.awards-list-item-head-main,
.awards-list-item-head-sub,
.misc-list-head-main,
.misc-list-head-sub,
.news-list-item-head-main,
.news-list-item-head-sub,
.points-list-item-head-main,
.points-list-item-head-sub,
.staff-list__item-head-main,
.staff-list__item-head-sub {
    display: block;
    margin: 0 auto;
    padding: 0;
}

.misc-list-head-main,
.misc-list-head-sub,
.points-list-item-head-main,
.points-list-item-head-sub,
.staff-list__item-head-main,
.staff-list__item-head-sub {
    text-align: left;
}

.misc-list-head-main + .misc-list-head-sub,
.points-list-item-head-main + .points-list-item-head-sub {
    margin-top: 15px;
}

.awards-list-item-head-sub,
.awards-list-item-head-sub + .awards-list-item-head-main {
    margin: 18px 0;
}

.awards-list-item-head-sub {line-height: 35px;}

.awards-list-item-head-main + .awards-list-item-head-sub {
    margin: 22px 0 0 0;
    padding: 17px 0 0 0;
    border-top: 2px solid $color_grey;
}

.misc-list-head-main + .misc-list-head-sub {
    margin-bottom: 17px;
}

.misc-list-head-sub + .misc-list {
    padding-top: 11px;
    border-top: 2px solid $color_grey;
}

.news-list-item-head-sub,
.news-list-item-head-sub + .news-list-item-head-main {
    margin: 18px 0;
}

.news-list-item-head-sub {line-height: 35px;}

.news-list-item-head-main + .news-list-item-head-sub {
    margin: 22px 0 0 0;
    padding: 17px 0 0 0;
    border-top: 2px solid $color_grey;
}

.points-list-item-head-main + .points-list-item-head-sub {
    padding-bottom: 18px;
    border-bottom: 2px solid $color_grey;
}

.points-list-item-text {
    position: relative;
    margin: 10px 0 0 0;
    padding: 0;
}

@media all and (max-width: 374px) {
    .awards-list-item,
    .news-list-item,
    .points-list-item {
        padding: 20px 24px 10px 24px;
    }

    .misc-list-wrap {
        /* This is to align the list with the other list types. */
        /* The others pad their items on both sides. */
        padding: 25px 24px 0 24px;
    }

    .misc-list {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        -moz-column-gap: 60px;
        -webkit-column-gap: 60px;
        column-gap: 60px;
    }

}

@media all and (min-width: 375px){
    .awards-list-item,
    .news-list-item,
    .points-list-item {
        padding: 40px 21px 0 21px;
    }

    .misc-list-wrap {
        /* This is to align the list with the other list types. */
        /* The others pad their items on both sides. */
        padding: 40px 24px 0 24px;
    }

    .misc-list {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        -moz-column-gap: 60px;
        -webkit-column-gap: 60px;
        column-gap: 60px;
    }

}

@media all and (min-width: 414px){
    .awards-list-item,
    .news-list-item,
    .points-list-item {
        padding: 30px 21px 0 21px;
    }
}

@media all and (min-width: 768px) {
    .awards-table {
        margin-left: 102px;
    }

    .points-list-item,
    .misc-list-wrap {
        padding: 30px 112px 0 112px;
    }

    .awards-list-item,
    .news-list-item {
        padding: 5px 112px 0 112px;
    }
}

@media all and (min-width: 960px) {
    .awards-list-item,
    .news-list-item {
        display: inline-block;
        width: 49.5%;
        padding: 0 0 0 0;
    }

    .points-list {
        padding-left: 80px;
    }

    .points-list-item {
        width: 40.5%;
        display: inline-block;
        padding: 20px 20px;
    }

    .misc-list-wrap,
    .awards-list-item {
        padding: 10px 100px;
    }
}

@media all and (min-width: 1024px) {
    .points-list-wrap {
        padding-left: 87px;
    }

    .misc-list-wrap {
        padding: 10px 43px 10px 105px;
    }

    .points-list{
        padding: 0;
    }

    .points-list-item{
        padding: 20px 20px;
        width: 32.3%;
    }

    .misc-list {
        column-gap: 110px;
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }

    .awards-list-item,
    .news-list-item {
        width: 32.3%;
        padding: 20px 20px;
    }

    .awards-list,
    .news-list {
        padding-left: 87px;
    }

}

@media all and (min-width: 1160px) {
    .misc-list-wrap {
        padding-top: 35px;
        padding: 10px 39px 10px 105px;
    }

    .points-list-item{
        width: 32.5%;
    }

    .awards-list-item,
    .news-list-item {
        width: 32.5%;
    }

}

@media all and (min-width: 1400px) {}



/*
 * Projects list.
 */

.project-list-wrap {
    position: relative;
    margin-left: 58px;
    padding: 0;
    text-align: left;
}

.project-list-entry-wrap-link {
    margin: 0;
    padding: 0;
    border: 0;
}

.project-list-entry-wrap {
    display: none;
}

.project-list-wrap,
.project-detail-wrap,
.project-detail-section-wrap {
}

.project-list-img {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 18px 38px 8px 0px;
}

.project-list-text-wrap {
    position: relative;
    margin: 24px 0 0 0;
    padding: 0;
    text-align: left;
}

.project-list-entry-title,
.project-list-entry-link {
    padding: 0;
}

.project-list-entry-title {
    font-size: 19px;
    font-weight: 500;
}

.project-list-entry-link {
    font-size: 15px;
}


@media all and (max-width: 374px){
    .project-list-wrap {
        margin-left: 24px;
    }

    .project-list-img  {
        padding: 18px 23px 8px 0;
    }

    .project-list-entry-wrap {
        &.filter-match {
            margin: 30px auto;
        }
    }

    .project-list-entry-wrap {
        &.filter-match {
            display: block;
            width: 100%;
            margin: 30px auto;
        }
    }

}

@media all and (min-width: 375px) {
    .project-list-wrap {
        margin-left: 23px;
    }

    .project-list-img  {
        padding: 18px 25px 8px 0;
    }

    .project-list-entry-wrap {
        &.filter-match {
            display: block;
            width: 100%;
            margin: 30px auto;
        }
    }
}

@media all and (min-width: 960px) {
    .project-list-text-wrap{
      margin: 5px 0 20px 0;
    }

    .project-list-entry-wrap {
        &.filter-match {
            display: inline-block;
            width: 47.5%;
            margin: 0;
            vertical-align: top;
        }
    }

    .project-list-entry-title {
      margin: 0;
    }

    .project-list-entry-link{
      margin: 7px 0 0 0;
    }
}

@media all and (min-width: 1024px) {
    .project-list-entry-wrap {
        &.filter-match {
            width: 49.5%;
            padding-bottom: 20px;
        }
    }
}

@media all and (min-width: 1160px) {

    .project-list-entry-title {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    .project-list-entry-link {
      display: inline-block;
      padding: 0;
      margin: 7px 0 0 0;
    }

    .project-list-text-wrap{
        display: flex;
        justify-content: space-between;
        width: 96%;
    }

    .project-list-entry-wrap {
        &.filter-match {
            padding-bottom: 25px;
        }
    }

}



/*
 * Project list filter.
 */

.project-filter-list-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: $color_black;
    z-index: 20;

    &.project-filter-list-on {
        width: 100vw;
        height: 100vh;
    }
}

.project-filter-list {
    position: absolute;
    top: 36%;
    left: 41%;
    transform: translate(-215%, -50%);
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-type: none;
}

.project-filter-list-item {
    display: block;
    color: $color_white;
    font-size: 50px;
    line-height: 200%;
}


.project-filter-list-item-active {
    color: $color_grey;
}

.project-filter-prompt {
    color: $color_light_grey;
    letter-spacing: .8px;
}

.project-filter-button {
    margin-left: 5px;
    border-bottom: 2px solid $color_black;
}

.project-filter-button:hover,
.project-filter-list-item:hover {
    cursor: pointer;
}


@media all and (max-width: 374px) {
    .project-filter-list-item {
        font-size: 37px;
    }

    .project-filter-list {
        left: 148%;
    }
}

@media all and (min-width: 375px) {
    .project-filter-list-item {
        font-size: 37px;
    }

    .project-filter-list {
        left: 130%;
    }
}

@media all and (min-width: 414px) {
    .project-filter-list {
        left: 120%;
    }
}

@media all and (min-width: 500px) {
    .project-filter-list {
        left: 95%;
    }
}

@media all and (min-width: 768px) {
    .project-filter-list {
        left: 68%;
    }
}

@media all and (min-width: 960px) {
    .project-filter-list {
        left: 55%;
    }
}

@media all and (min-width: 1160px) {
    .project-filter-list {
        left: 43%;
    }
}



/*
 * Staff list.
 *
 * A couple structural rules for the `staff-list__item` are common to
 * the `awards-list-item`, etc, which are grouped above.
 */

.staff-list {
    width: 89%;
    margin: 0 auto;
    font-size: 0;
}


.staff-list__item {
    position: relative;
    width: 100%;
    margin: 40px auto 0px auto;
    overflow: hidden;
}

/*
 * ATTENTION: this weird arrangement enables a few desirable things:
 * - There will be a white background behind every slide, so even
 *   short slides will appear to be the full height of the slider.
 * - The dimensions of the background screen will be determined by
 *   the dimensions of the slider.
 * - The height of the slider is determined by the image.
 * - So the whole thing scales up and down easily according to the
 *   dimensions of the image.
 */

.staff-list__slides-wrap {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
}


.staff-list__slides-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    white-space: nowrap;
    transform: translateX(-0%);
    transition: transform 0.3s ease;
}


.black-on-white {
    .staff-list__slides-bg {
        background-color: $color_black;
    }
    .staff-list__slides-item {
        color: $color_white;
        a {
            color: $color_white;
        }
    }
}

.white-on-black {
    .staff-list__slides-bg {
        background-color: $color_white;
    }
    .staff-list__slides-item {
        color: $color_black;
        a {
            color: $color_black;
        }
    }
}


.staff-list__slides-item {
    display: inline-block;
    position: relative;
    margin: 0 2px 0 0;
    padding: 0;
    width: 100%;
    vertical-align: top;
    white-space: normal;
}


.staff-list__item-ref-img {
    display: block;
    width: 100%;
    opacity: 0;
    z-index: -1;
}

.staff-list__slides-slider,
.staff-list__buttons-wrap {
    z-index: 1;
}

.staff-list__slides-item--image {
}

.staff-list__slides-item--quote,
.staff-list__slides-item--facts {
    height: 100%;
    background-color: $color_white;
}

.staff-list__slides-item--quote {
    padding: 120px 30px;
}

.staff-list__slides-item--facts {
    background-color: $color_white;
    padding: 80px 30px;
}


.staff-list__img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
}

.staff-list__copy-quote,
.staff-list__copy-fact {
    font-size: 16px;
    line-height: 147%;
}

.staff-list__copy-quote {
    font-weight: bold;
}

.staff-list__copy-fact {
    font-weight: normal;
}

.staff-list__copy-fact--prompt,
.staff-list__copy-fact--response {
    margin: 0;
}

.staff-list__copy-fact--prompt {
    font-weight: bold;
}


.staff-list__fact-wrap + .staff-list__fact-wrap {
    margin-top: 40px;
}


.staff-list__copy-wrap {
    width: 100%;
    margin: 30px auto 0 auto;
    padding: 0;
}


.staff-list__buttons-wrap {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
}

.staff-list__button {
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: $color_white;
    cursor: pointer;

    &:after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 35%;
        height: 35%;
        border-color: $color_black;
    }
}

.staff-list__button--bk {
    left: 10px;

    &:after {
        border-width: 0 0 2px 2px;
        border-style: none none solid solid;
        transform: translate(-34%, -50%) rotate(45deg);
    }
}

.staff-list__button--fw {
    right: 10px;

    &:after {
        border-width: 0 2px 2px 0;
        border-style: none solid solid none;
        transform: translate(-66%, -50%) rotate(-45deg);
    }
}


.staff-list__item-head-main + .staff-list__item-head-sub {
    margin-top: 10px;
}


.staff-list__email-link {
    display: inline-block;
    margin: 15px 0 0 0;
    font-size: 15px;
}


@media all and (min-width: 768px) {
    .staff-list {
        width: 80%;
        margin: 0 35px 0 auto;
    }

    .staff-list__item {
        margin-bottom: 30px;
    }

    .staff-list__slides-wrap {
        margin: 0 0 74px;
    }

    .staff-list__slides-item--facts {
        padding: 120px 90px;
    }
}


@media all and (min-width: 960px) {
    .staff-list {
        width: 90%;
        margin: 0 0 74px auto;
    }

    .staff-list__item {
        width: 45%;
        display: inline-block;
        margin: 60px 60px 0 0;

        &:nth-child(even) {
            margin-right: 0;
        }
    }

    .staff-list__slides-wrap {
        margin: 0 0 30px 0;
    }
}


@media all and (min-width: 1024px) {
    .staff-list__item {
        width: 45%;
        margin: 30px 80px 0 0;
    }

    .staff-list__item-head-main {
        margin-top: 20px;
    }

    .staff-list__slides-item--facts {
        padding: 90px 60px;
    }

    .staff-list__email-link {
        margin-top: 20px;
    }
}


@media all and (min-width: 1160px) {
    .staff-list__item {
        width: 28%;
        margin: 20px 40px 20px 0;

        &:nth-child(even) {
            margin-right: 40px;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    .staff-list__slides-item--facts {
        padding: 30px 30px;
    }

    .staff-list__fact-wrap + .staff-list__fact-wrap {
        margin-top: 30px;
    }
}


@media all and (min-width: 1400px) {
    .staff-list {
        width: 92%;
    }

    .staff-list__item {
        width: 30%;
        margin: 20px 55px 25px 0;

        &:nth-child(even) {
            margin-right: 55px;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    .staff-list__slides-item--facts {
        padding: 90px 40px;
    }

    .staff-list__fact-wrap + .staff-list__fact-wrap {
        margin-top: 40px;
    }
}




/*
 * Contact page.
 */

.contact-page-wrap {
    position: relative;
    margin: 0;
    padding: 42px 0 30px 0;
}

.contact-page-blocks-small {
    display: block;
}

.contact-page-blocks-large {
    display: none;
}

.contact-page-block {
    display: block;
    vertical-align: top;
    margin: 60px 0;
    padding: 0;
    text-align: center;
    color: $color_grey;
    a {
        color: $color_grey;
    }
}

.contact-page-links-list {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.contact-page-links-list,
.contact-page-text {
    margin: 0 auto;
    text-align: left;
    font-size: 17px;
}

.contact-page-links-list-item,
.contact-page-text {
    margin: 0;
    padding: 0;
    line-height: 220%;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}


.contact-page-map-flap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    /* The height of this is correlated to the `.nav-logo-img`.
    height: 128px; */
    background-color: #ffffff;
    z-index: 5;
}

.contact-page-map-wrap {
    width: 100vw;
    height: 100vh;
}

#map-wrap {
    width: 100%;
    height: 100%;
}


@media all and (max-width: 374px) {
    .contact-page-block {
        display: inline-block;
        width: 90%;
        margin: 30px 0;

        &:first-child {
            margin-top: 114px;
        }
    }

    .contact-page-text {
        margin: 60px 0 0 15px;
    }

    .contact-page-links-list-item,
    .contact-page-block,
    .contact-page-text {
        font-size: 13px;
    }
}

@media all and (min-width: 375px) {
    .contact-page-block {
        display: inline-block;
        margin: 50px 0;
        width: 90%;

        &:first-child {
            margin-top: 114px;
        }
    }

    .contact-page-text {
        margin: 60px 0 0 15px;
    }

    .contact-page-links-list-item,
    .contact-page-block,
    .contact-page-text {
        font-size: 15px;
    }
}

@media all and (min-width: 375px) {
    .contact-page-block {
        display: inline-block;
        margin: 60px 0;
        width: 90%;

        &:first-child {
            margin-top: 114px;
        }
    }

    .contact-page-text {
        margin: 60px 0 0 15px;
    }
}

@media all and (min-width: 768px) {
    .contact-page-blocks-small {
        display: none;
    }

    .contact-page-blocks-large {
        display: block;
    }

    .contact-page-block {
        display: inline-block;
        margin: 110px 0 43px 8px;
        width: 34%;
    }

    .contact-page-text {
        margin: 0px 0 0 15px;
    }
}

@media all and (min-width: 960px) {
    .contact-page-block {
        width: 35%;
        margin: 110px 0 43px 90px;
    }
}

@media all and (min-width: 1000px) {
    .contact-page-block {
        width: 36%;
    }
}

@media all and (min-width: 1100px) {
    .contact-page-block {
        width: 36%;
    }
}

@media all and (min-width: 1200px) {
    .contact-page-block {
        width: 34%;
    }
}



/*
 * More projects.
 */

.more-projects-wrap {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 88%;
}

.more-projects-cell {
    display: block;
    text-align: left;
    vertical-align: top;
}

.more-projects-link.arrow-link {
    &:after {
        display: block;
        top: 0;
        margin-top: 20px;
    }
}

@media all and (max-width: 374px) {
    .more-projects-cell {
        margin: 0;
        padding: 0;
    }

    .more-projects-wrap {
        margin-left: 35px;
    }

    .more-projects-link {
        font-size: 20px;
    }
}

@media all and (min-width: 375px) {

    .more-projects-wrap {
        margin-left: 35px;
    }

    .more-projects-link {
        font-size: 25px;
    }

    .more-projects-cell:last-child {
        margin-top: 30px;
    }
}

@media all and (min-width: 768px) {

    .more-projects-wrap {
        margin-left: 43px;
    }

    .more-projects-link {
        font-size: 30px;
    }
}

@media all and (min-width: 960px) {

    .more-projects-wrap {
        width: 100%;
        margin: 80px 0 0 62px;
    }

    .more-projects-link {
        font-size: 30px;
    }

    .more-projects-cell {
        display: inline-block;
        width: 49.5%;

        &:first-child {
            margin-top: 30px;
        }
    }

}

@media all and (min-width: 1400px) {

    .more-projects-link {
        font-size: 38px;
    }

    .more-projects-wrap {
        margin: 0 auto;
        padding-left: 30px;
    }
}



/*
 * Footer.
 */

.foot-cells-wrap {
    display: block;
    margin: 0;
}

.foot-cell {
    display: block;
    vertical-align: top;
    /* This is to align the foot cells with the various table cells. */
    padding: 0 0 0 100px;
    text-align: left;
}

.foot-nav-list {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.foot-nav-list-item {
    margin: 20px 0 0 30px;
    padding: 0;
    font-size: 15px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 60px;
    }
}

.foot-nav-list-link,
.foot-copyright-text {
    margin: 0;
    padding: 1px 0;
    font-size: 15px;
}

@media all and (max-width: 374px){
    .foot-cells-wrap {
        padding: 30px 0 0 0;
    }

    .foot-nav-list-item {
        &:last-child {
            margin-bottom: 40px;
        }
    }

    .foot-cell {
        padding: 0;
    }

    .foot-copyright-text {
        margin: 0 0 0 25px;
        padding-bottom: 45px;
    }

}

@media all and (min-width: 375px){
    .foot-cells-wrap {
        padding: 30px 0 0 0;
    }

    .foot-cell {
        padding: 0;
    }

    .foot-copyright-text {
        margin: 0 0 0 25px;
        padding-bottom: 45px;
    }
}

@media all and (min-width: 768px){
    .foot-cells-wrap {
        padding: 32px 0 0 0;
    }
}

@media all and (min-width: 960px){
    .foot-cells-wrap {
        padding: 80px 0 60px 0;
    }
}

@media all and (min-width: 1160px) {
    .foot-cell,
    .foot-cell:last-child {
        display: inline-block;
        width: 33%;
        text-align: left;
    }
}





/*
 * The loading cloak and spinner.
 */

#loading-cloak {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 100;
}


#loading-cloak-body-wrap {
    @include abscent;
    text-align: center;
}


#loading-cloak-logo,
#loading-spinner-wrap {
    display: block;
    margin: 20px auto;
    padding: 0;
    max-width: 90vw;
}


#loading-spinner-box {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    padding: 0;

    &.a {
        .spinner-part:nth-child(0n+1) {
            transform: translateX(0px) rotate(90deg);
        }
        .spinner-part:nth-child(0n+2) {
            transform: translateX(0px);
        }
        .spinner-part:nth-child(0n+3) {
            transform: translateX(0px);
        }
        .spinner-part:nth-child(0n+4) {
            transform: translateX(0px);
        }
        .spinner-part:nth-child(0n+5) {
            transform: translateX(0px);
        }
    }

    &.b {
        .spinner-part:nth-child(0n+1) {
            transform: translateX(80px);
        }
        .spinner-part:nth-child(0n+2) {
            transform: translateX(-20px) rotate(270deg);
        }
        .spinner-part:nth-child(0n+3) {
            transform: translateX(20px);
        }
        .spinner-part:nth-child(0n+4) {
            transform: translateX(-40px);
        }
        .spinner-part:nth-child(0n+5) {
            transform: translateX(-40px);
        }
    }

    &.c {
        .spinner-part:nth-child(0n+1) {
            transform: translateX(40px);
        }
        .spinner-part:nth-child(0n+2) {
            transform: translateX(40px);
        }
        .spinner-part:nth-child(0n+3) {
            transform: translateX(-40px) rotate(180deg);
        }
        .spinner-part:nth-child(0n+4) {
            transform: translateX(20px);
        }
        .spinner-part:nth-child(0n+5) {
            transform: translateX(-60px);
        }
    }

    &.d {
        .spinner-part:nth-child(0n+1) {
            transform: translateX(20px);
        }
        .spinner-part:nth-child(0n+2) {
            transform: translateX(20px);
        }
        .spinner-part:nth-child(0n+3) {
            transform: translateX(40px);
        }
        .spinner-part:nth-child(0n+4) {
            transform: translateX(-60px) rotate(-90deg);
        }
        .spinner-part:nth-child(0n+5) {
            transform: translateX(-20px);
        }
    }

    &, &.e {
        .spinner-part:nth-child(0n+1) {
            transform: translateX(60px);
        }
        .spinner-part:nth-child(0n+2) {
            transform: translateX(60px);
        }
        .spinner-part:nth-child(0n+3) {
            transform: translateX(-20px);
        }
        .spinner-part:nth-child(0n+4) {
            transform: translateX(-20px);
        }
        .spinner-part:nth-child(0n+5) {
            transform: translateX(-80px) rotate(-270deg);
        }
    }
}


.spinner-part {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 5px;
    padding: 0;

    transition: transform 1.0s ease;

    &.a {
        background-color: hsl(0, 0%, 40%);
    }
    &.b {
        background-color: hsl(0, 0%, 55%);
    }
    &.c {
        background-color: hsl(0, 0%, 70%);
    }
    &.d {
        background-color: hsl(0, 0%, 85%);
    }
    &.e {
        background-color: hsl(0, 100%, 50%);
    }
}
